import React from "react";
import {
  Grid,
  Box,
  Link,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AiOutlineMedium } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import {} from "react-feather";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#10063C",
    position: "relative",
    // paddingTop: "50px ",
    backgroundPosition: " bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    // "&"
    "& h5": {
      fontSize: "16px",
      letterSpacing: "2px",
      color: "#FFFFFF",
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& p": {
      fontSize: "12px",
      color: "#FFFFFF",
      margin: "0",
      padding: "10px 0",
    },
  },
  footerBg: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "0",
  },
  ListItem: {
    paddingLeft: "0px",
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  signupBtn: {
    color: "#fff",
    display: "flex",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    minWidth: "100px",
    borderRadius: "50px",
    position: "absolute",
    top: "5px",
    right: "5px",
    boxShadow:
      "0px 8px 24px rgba(38, 50, 56, 0.1), 0px 16px 32px rgba(38, 50, 56, 0.08)",
    lineHeight: "36px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: " 1px",
    background: "#040405",
    "&:hover": {
      background: "#040405",
      color: "#fff",
    },
  },

  socialicon: {
    display: "flex",
    "& a": {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      // backgroundColor: "#00bdff",
      color: "#fff",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      border: "1px solid #fff",
      marginRight: "9px",
      display: "flex",

      "&:hover": {
        border: "1px solid #14bbf5;",
        color: "#14bbf5;",
      },
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  inputBox: {
    position: "relative",
  },
  footerBgImg: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: "1",
  },
  textFild: {
    position: "relative",

    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#000",
      minWidth: "90px",
      fontSize: "18px",
      fontWeight: "700",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#000",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <Box className={classes.footerSection}>
      <Container maxWidth="lg">
        <Grid
          style={{ borderBottom: "1px solid #FFFFFF" }}
          container
          justify="space-around"
          spacing={1}
        >
          <Grid item item xs={12} sm={6} md={3}>
            <img alt="" src="images/logo.png" />
          </Grid>

          <Grid item item xs={6} sm={6} md={3} align="left">
            <Box class=" col-lg-4 col-md-4 col-sm-6 col-12">
              <Typography variant="h5">Document</Typography>

              <ul className="list-unstyled footer_text">
                <li>
                  <a
                    href="https://docsend.com/view/88c9x443pgvj9e39"
                    target="_blank"
                  >
                    White Paper
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://docsend.com/view/4aw47iriw5usevrh"
                    target="_blank"
                  >
                    One Pager
                  </a>
                </li>
                <li>
                  <a
                    href="https://docsend.com/view/5di9r66ns3vw2jep"
                    target="_blank"
                  >
                    Pitch Deck
                  </a>
                </li> */}
                <li>
                  <a href="https://github.com/GainPool" target="_blank">
                    Github
                  </a>
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid item item xs={6} sm={6} md={3} align="left">
            <Box class=" col-lg-4 col-md-4 col-sm-6 col-12">
              <Typography variant="h5">General</Typography>

              <ul class="list-unstyled footer_text">
                <li>
                  <a href="/about">About Us</a>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    // onClick={() => {
                    //   document.getElementById("Features").scrollIntoView();
                    // }}
                    href="/features"
                  >
                    Features
                  </a>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    // onClick={() => {
                    //   document.getElementById("Roadmap").scrollIntoView();
                    // }}
                    href="/roadmap"
                  >
                    Roadmap
                  </a>
                </li>
                {/* <li>
                  <a href="/launchpad-service">Services</a>
                </li> */}
              </ul>
            </Box>
          </Grid>

          <Grid item item xs={12} sm={6} md={3} align="left">
            <Typography variant="h5">Social Media</Typography>
            <Box align="left" mt={3} mb={3} className="follow">
              <a href="https://medium.com/gainpool" target="_blank">
                <AiOutlineMedium />
              </a>
              <a href="https://twitter.com/GainPool" target="_blank">
                <BsTwitter />
              </a>
              <a
                href="https://www.linkedin.com/company/gainpool/"
                target="_blank"
              >
                <FaLinkedinIn />
              </a>
              <a href="https://github.com/GainPool" target="_blank">
                <AiFillGithub />
              </a>
              <a href="https://t.me/GainPoolcommunity" target="_blank">
                <FaTelegramPlane />
              </a>
            </Box>
            <ul className="list-unstyled footer_text">
              <li>
                <a href="/brand-assets" target="_blank">
                  Brand Assets
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
      <p align="center">
        @ 2021 GainPool{" "}
        <a style={{ color: "inherit" }} href="/terms-of-use">
          Terms of Use{" "}
        </a>{" "}
        and{" "}
        <a style={{ color: "inherit" }} href="/privacy-policy">
          Privacy Policy
        </a>
      </p>
    </Box>
  );
}
