import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/features",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/brandAssets/Features")),
  },
  {
    exact: true,
    path: "/roadmap",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/brandAssets/Roadmap copy")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About/AboutUs")),
  },
  {
    exact: true,
    path: "/brand-assets",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/brandAssets/brandIndex")),
  },
  {
    exact: true,
    path: "/terms-of-use",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/TermsOfUse/TermsOfUse")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/PrivacyPolicy/PrivacyPolicy")
    ),
  },
  {
    exact: true,
    path: "/ido-services",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Service/IDO")),
  },
  {
    exact: true,
    path: "/launchpad-service",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Service/Launchpad")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
